import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// The below code till line 24 is for authenticating users using Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCLIM1G3DHRGTez67YMkUop_CHYVRPyQfg",
  authDomain: "react-blog-app-5dfe8.firebaseapp.com",
  projectId: "react-blog-app-5dfe8",
  storageBucket: "react-blog-app-5dfe8.appspot.com",
  messagingSenderId: "145213871287",
  appId: "1:145213871287:web:66c6e9d9881dae058a2932",
  measurementId: "G-N66DDTBT4X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
